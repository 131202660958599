<template>
  <v-container
    fluid
    class="light-blue lighten-5 pb-16"
  >
    <v-row
      justify="center"
      class="pb-16"
    >
      <v-col
        cols="12"
        md="12"
      >
        <base-material-card
          icon="mdi-file-document-outline"
          color="primary"
        >
          <template v-slot:after-heading>
            <div class="font-weight-light card-title mt-2">
              {{ $t('userpolicies.inForcePolicies') }}
            </div>
          </template>
          <v-row>
            <v-col class="d-flex justify-center">
              <div class="primary--text text-center text-h1 mt-2 mb-4">
                {{ $t('userpolicies.myPolicies') }}
              </div>
            </v-col>
          </v-row>
          <div>
            <v-row class="d-flex justify-center">
              <v-col
                v-if="policyHeaders"
                v-model="policyHeaders"
                cols="12"
                sm="10"
                md="10"
              >
                <v-data-table
                  :key="refresh"
                  :no-data-text="$t('common.noDataFound')"
                  :headers="policyHeaders"
                  :items="allPolicies"
                  :sort-by="['created']"
                  :sort-desc="[false]"
                  multi-sort
                  expand-icon="mdi-eye-outline"
                  :loading="loading"
                  single-expand
                  item-key="policyNum"
                  show-expand
                  class="elevation-1 custom_table_class"
                >
                  <template v-slot:top>
                  </template>
                  <template v-slot:[`item.provider`]="{ item }">
                    <div class="d-inline-flex align-center">
                      <v-img
                        :src="item.imgsrc"
                        :height="item.imgHeight"
                        :width="item.imgWidth"
                        class="pa-3"
                      >
                      </v-img>
                      <span
                        class="ml-2 provider-txt"
                      >
                        <span
                          v-if="item.provider.includes('Blanket Life')"
                          v-html="$t('common.blanketText')"
                        >
                        </span>
                        <span v-if="!item.provider.includes('Blanket Life')">{{ item.provider }}</span>
                        <br>
                        <span v-if="item.type === 'Term Life'">
                          {{ $t('common.termLife') }}
                        </span>
                        <span v-else>
                          {{ item.type }}
                        </span>
                      </span>
                    </div>
                  </template>
                  <template v-slot:[`item.price`]="{ item }">
                    <div>
                      <span style="font-size: 14px !important;">{{ format_amt(item.price) }}</span>
                    </div>
                  </template>

                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon
                      class="mr-2"
                      color="primary"
                    >
                      mdi-email
                    </v-icon>
                    <v-icon color="primary">
                      mdi-download
                    </v-icon>
                  </template>
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                      <v-row class="mt-4">
                        <v-col class="d-flex justify-end pb-6">
                          <!-- <span><v-btn
                            class="mx-4 font-weight-bold"
                            color="primary"
                            :loading="btnLoading.download"
                            @click="getPolicy(item.provider, item.policyNum, 'download')"
                          >
                            Download
                            <v-icon
                              right
                              dark
                            >
                              mdi-download
                            </v-icon>
                          </v-btn></span> -->
                          <span><v-btn
                            class="mx-4 font-weight-bold"
                            color="primary"
                            :loading="btnLoading.email"
                            @click="getPolicy(item.provider, item.policyNum, 'email')"
                          >
                            {{ $t('userpolicies.emailPolicy') }}
                            <v-icon
                              right
                              dark
                            >
                              mdi-email
                            </v-icon>
                          </v-btn>
                          </span>
                        </v-col>
                      </v-row>
                      <div v-if="item.provider === 'Generali'">
                        <ul
                          class="text-h4 pa-2"
                          style="list-style: none;"
                        >
                          <li class="pa-1">
                            {{ $t("userpolicies.product") }}: {{ item.product }}
                          </li>
                          <li
                            v-for="(cover, idx) in item.coverages"
                            :key="idx"
                            class="pa-1"
                          >
                            <div v-if="cover.coveragedescription[0].includes('Trip Cancellation')">
                              {{ cover.coveragedescription[0] }} <br>60% of penalty amount
                            </div>
                            <div v-if="!cover.coveragedescription[0].includes('Trip Cancellation')">
                              {{ cover.coveragedescription[0] }}
                            </div>
                            <!-- <div v-if="!cover.coveragedescription[0].includes('Service')">
                              Effective date: {{ cover.effectivedate[0] }} Expiration Date: {{ item.returndate }}
                            </div> <br> <v-divider></v-divider> -->
                            <div v-if="cover.unlimited[0] !=='1' && !cover.coveragedescription[0].includes('Trip Cancellation')">
                              Individual limit: {{ format_amt(cover.individuallimit[0]) }}<br>Policy limit: {{ format_amt(cover.policylimit[0]) }}
                            </div>
                            <div v-if="cover.unlimited[0] !=='0' && !cover.coveragedescription[0].includes('Trip Cancellation')">
                              Included
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div v-if="item.provider === 'Trupanion'">
                        <ul
                          class="text-h4 pa-2"
                          style="list-style: none;"
                        >
                          <li>Provider: Trupanion </li>
                          <li>Policy Holder: {{ item.FirstName }} {{ item.LastName }} </li>
                          <div
                            v-for="(pet, index) in item.Pets"
                            :key="index"
                          >
                            Pet Name: {{ pet.Name }}<br> Gender: {{ pet.Gender }}<br> Breed: {{ pet.BreedName }}
                            <div>
                              Coverage: {{ pet.PremiumCalculation.CoinsurancePercent }}% Deductible: {{ format_amt(pet.PremiumCalculation.Deductible) }}
                            </div>
                          </div>
                          <li>Price: {{ format_amt(item.price) }}</li>
                          <li>Policy ID: {{ item.PolicyId }}</li>
                          <li>Policy Number : {{ item.policyNum }}</li>
                          <li>Ref: {{ item.ReferenceNumber }}</li>
                        </ul>
                      </div>
                      <div v-if="item.provider === 'Tugo'">
                        <ul
                          class="text-h4 pa-2"
                          style="list-style: none;"
                        >
                          <li>Type: {{ item.tripType }}</li>
                          <li>Price: {{ format_amt(item.price) }}</li>
                          <li>Trip Start Date: {{ item.startDate }}</li>
                          <li>Trip End Date: {{ item.endDate }}</li>
                          <li>{{ $t('userpolicies.effectiveDate') }}: {{ item.effectiveDate }}</li>
                          <li>Created: {{ item.createdDate }}</li>
                        </ul>
                      </div>
                      <div v-if="item.provider === 'SBLI' || item.provider === 'Centrian'">
                        <ul
                          class="text-h4 pa-2"
                          style="list-style: none;"
                        >
                          <li>{{ $t('userpolicies.value') }}: {{ format_amt(item.coverage) }}</li>
                          <li>{{ item.term }} {{ $t('common.years') }}</li>
                          <li>Premium: {{ format_amt(item.premium) }}</li>
                          <li>Provider: {{ item.provider }}</li>
                          <li>Policy Number: {{ item.policyNum }}</li>
                        </ul>
                      </div>
                      <div v-if="item.provider === 'Blanket Life underwritten by Humania Assurance Inc.'">
                        <ul
                          class="text-h4 pa-2"
                          style="list-style: none;"
                        >
                          <li>{{ $t('userpolicies.value') }}: {{ format_amt(item.coverage) }}</li>
                          <li>{{ $t('userpolicies.term') }}: {{ item.term }} {{ $t('common.years') }}</li>
                          <li>{{ $t('userpolicies.premium') }}: {{ format_amt(item.premium) }}</li>
                          <li>{{ $t('userpolicies.policyFee') }}: {{ format_amt(item.policyFee) }}</li>
                          <li>{{ $t('userpolicies.provider') }}: <span v-html="getLocalProvider()"></span></li>
                          <li>{{ $t('userpolicies.policyNumber') }}: {{ item.policyNum }}</li>
                        </ul>
                      </div>
                      <div v-if="item.provider === 'Stillwater'">
                        <ul
                          class="text-h4 pa-2"
                          style="list-style: none;"
                        >
                          <li>Policy Number: {{ item.policyNum }}</li>
                          <li>Product: {{ item.type }}</li>
                          <li>Premium: ${{ item.price }}</li>
                          <li>Provider: {{ item.provider }}</li>
                          <li>{{ $t('userpolicies.effectiveDate') }}: {{ item.effectiveDate }}</li>
                          <li>{{ $t('userpolicies.expirationDate') }}: {{ item.expirationDate }}</li>
                        </ul>
                      </div>
                    </td>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </div>
          <!---------------------------PET POLICIES------------------------->
          <!------------------------------ CANADIAN TRAVEL ------------------------>
          <!-------------------------------------------LIFE POLICIES----------------------------------------->
        </base-material-card>
      </v-col>
    </v-row>
    <v-row justify="space-around">
      <v-col cols="auto">
        <v-dialog
          v-model="emailConfirmDialog"
          transition="dialog-bottom-transition"
          width="auto"
        >
          <v-card class="pa-0">
            <v-card-title class="justify-center font-weight-bold text-h5 green--text">
              {{ $t('success') }}!
            </v-card-title>
            <v-card-text class="text-center">
              <div
                v-for="(text,index ) in $t('policySent')"
                :key="index"
              >
                {{ text }}
              </div>{{ user.email }}.
            </v-card-text>
            <v-row>
              <v-col>
                <div class="d-flex justify-center">
                  <v-btn
                    color="primary"
                    class="mb-2 mt-4 font-weight-bold mr-0"
                    @click="emailConfirmDialog = false"
                  >
                    {{ $t('loginPage.close') }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-overlay v-if="loading">
      <v-row
        class="mt-12"
      >
        <v-col class="d-flex justify-center">
          <ellipse-progress
            :loading="loading"
            :size="180"
            img-src="src/assets/images/termlife-icon.png"
            thickness="5"
            empty-thickness="3"
            line-mode="out 5"
            legend="false"
            animation="rs 700 1000"
            font-size="1.5rem"
            color="#00A1B7"
            empty-color-fill="transparent"
            filename="termlife-icon.png"
          >
          </ellipse-progress>
        </v-col>
      </v-row>
    </v-overlay>
  </v-container>
</template>
<script>
  import moment from 'moment'
  import { jsPDF } from 'jspdf'
  import autoTable from 'jspdf-autotable'
  import imageToBase64 from 'image-to-base64/browser'
  import { localCurrency } from '@/utils/common'
  import { mapState } from 'vuex'

  var numeral = require('numeral')

  export default {
    name: 'UserProfile',
    components: {
    },
    data () {
      return {
        refresh: 0,
        emailConfirmDialog: false,
        loading: true,
        btnLoading: {
          email: false,
          download: false
        },

        policies: [],
        pdfPolicy: [],
        pdfItem: '',
        pdfName: '',
        timeout: 5000,
        alertmessage: '',
        contentRendered: false,
        progress: 0,
        generatingPdf: false,
        pdfDownloaded: false,
        allPolicies: [],
      }
    },
    computed: {
      ...mapState(['activeUser', 'user', 'canadianTermLife']),
      userKey () {
        this.refresh += 1
        return this.activeUser?.key ?? this.user.id
      },
      policyHeaders () {
        return [
          { text: this.$t('userpolicies.provider'), value: 'provider', class: 'header-txt' },
          { text: `${this.$t('userpolicies.policy')}`, value: 'policyNum', align: 'center' },
          { text: this.$t('userpolicies.created'), value: 'created', align: 'center' },
          { text: this.$t('userpolicies.price'), value: 'price', align: 'right' },
          { text: this.$t('userpolicies.viewPolicy'), value: 'data-table-expand', align: 'center' },
        ]
      },
    },

    watch: {
      'canadianTermLife.CATermPolicies' (newPolicies, oldPolicies) {
        this.allPolicies = this.getAllPolicies()
      },
    },

    created () {
      this.$gtag.pageview('UserPolicies')
      this.pdfPolicy = []
      this.pdfHeader = []
    },

    mounted () {
      this.loadPolicies()
    },
    methods: {

      loadPolicies () {
        // Ensure loading state for better UX
        this.loading = true

        if (this.userKey) {
          this.$store.dispatch('resetAllPolicies', [])
          this.$store.dispatch('loaduserpolicies', { userkey: this.userKey })
          this.$store.dispatch('loaduserpoliciesAfficiency', { userkey: this.userKey })
          this.$store.dispatch('loaduserpoliciesTrupanion', { userkey: this.userKey })
          this.$store.dispatch('loaduserpoliciesCanadianTravel', { userkey: this.userKey })
          this.$store.dispatch('loaduserpoliciesUSATravel', { userkey: this.userKey })
          this.$store.dispatch('loadUserPoliciesStillwaterRenters', { userkey: this.userKey })
          this.$store.dispatch('loadUserPoliciesStillwaterCondo', { userkey: this.userKey })
          this.$store.dispatch('loaduserpoliciesCATerm', { userkey: this.userKey })

          this.allPolicies = this.getAllPolicies()
        }

        this.loading = false // End loading state after data is processed
      },
      getAllPolicies () {
        let policies = []
        let trupanionPolicies = this.$store.getters.getTrupanionPolicies ?? []
        let generaliPolicies = this.$store.getters.getTravelUSAPolicies ?? []
        let tugoPolicies = this.$store.getters.getCanadianTravelPolicies ?? []
        let rentalPolicies = this.$store.getters.getRentalPolicies ?? []
        let condoPolicies = this.$store.getters.getCondoPolicies ?? []
        let afficiencyPolicies = this.$store.getters.getUSTermPolicies ?? []
        let humaniaPolicies = this.$store.getters.getCATermPolicies ?? []
        // let lifePolicies = this.$store.getters.getuserpolicies
        let created = moment().subtract(2, 'day').format('YYYY-MM-DD')
        trupanionPolicies.forEach(policy => {
          if (policy.created) {
            created = moment(policy.created * 1000).format('YYYY-MM-DD')
          }
          let price = 0
          policy.Pets.forEach(pet => {
            price += pet.PremiumCalculation.Premium
          })
          policies.push({
            provider: 'Trupanion',
            type: 'Pet',
            imgsrc: '/img/pet-badge.png',
            CountryCode: policy.CountryCode,
            Email: policy.Email,
            FirstName: policy.FirstName,
            Id: policy.Id,
            LastName: policy.LastName,
            Link: policy.Link,
            Pets: policy.Pets,
            PolicyId: policy.PolicyId,
            PolicyNumber: policy.PolicyNumber,
            PostalCode: policy.PostalCode,
            ReferenceNumber: policy.ReferenceNumber,
            StateOrProvinceCode: policy.StateOrProvinceCode,
            policyNum: policy.PolicyNumber,
            price: price,
            imgHeight: '36',
            imgWidth: '36',
            created: created,
          })
        })
        generaliPolicies.forEach(policy => {
          if (policy.created > 0) {
            created = moment(policy.created * 1000).format('YYYY-MM-DD')
          }
          const price = policy.totalPrice.replace('$', '')
          policies.push(
            {
              provider: 'Generali',
              type: 'Travel',
              policyNum: policy.policynumber,
              price: price,
              imgsrc: '/img/travel-avatar.png',
              imgHeight: '36',
              imgWidth: '36',
              product: policy.product,
              coverages: policy.coverages,
              returndate: policy.returndate,
              created: created
            })
        })
        if (afficiencyPolicies) {
          afficiencyPolicies.forEach(policy => {
            if (policy.created > 0) {
              created = moment(policy.created * 1000).format('YYYY-MM-DD')
            }

            policies.push(
              {
                provider: policy.personalDetails.state === 'CT' ? 'Centrian' : 'SBLI',
                type: 'Term Life',
                policyNum: policy.PolicyNumber,
                premium: policy.Premium,
                price: policy.Premium,
                imgsrc: '/img/termlife-avatar.png',
                imgHeight: '36',
                imgWidth: '36',
                clientNumber: policy.ClientNumber,
                coverage: policy.Coverage,
                term: policy.Term,
                created: created
              })
          })
        }
        if (humaniaPolicies) {
          humaniaPolicies.forEach(policy => {
            if (policy.EffectiveDate) {
              created = this.formatDate(policy.EffectiveDate)
            }
            policies.push(
              {
                provider: 'Blanket Life underwritten by Humania Assurance Inc.',
                type: 'Term Life',
                policyNum: policy.PolicyNumber,
                premium: policy.Premium,
                price: policy.Price,
                policyFee: policy.PolicyFee,
                imgsrc: '/img/termlife-avatar.png',
                imgHeight: '36',
                imgWidth: '36',
                coverage: policy.Coverage,
                term: policy.Term,
                effectiveDate: policy.EffectiveDate,
                created: created
              })
          })
        }
        tugoPolicies.forEach(policy => {
          if (policy.created > 0) {
            created = moment(policy.created * 1000).format('YYYY-MM-DD')
          }
          policies.push(
            {
              provider: 'Tugo',
              policyinfo: policy.policyinfo,
              type: 'Travel',
              policyNum: policy.policyinfo.policyNumber.referenceNumber,
              price: policy.totalPrice,
              tripType: policy.policyinfo.tripType,
              startDate: policy.trip.startDate,
              endDate: policy.trip.endDate,
              effectiveDate: policy.policyinfo.effectiveDate,
              createdDate: policy.policyinfo.createdDate,
              imgsrc: '/img/travel-avatar.png',
              imgHeight: '36',
              imgWidth: '36',
              tripStart: policy.trip.startDate,
              tripEnd: policy.trip.endDate,
              effective: policy.policyinfo.effectiveDate,
              created: policy.policyinfo.createdDate,
            })
        })
        rentalPolicies.forEach(policy => {
          if (policy.created > 0) {
            created = moment(policy.created * 1000).format('YYYY-MM-DD')
          }
          policies.push(
            {
              provider: 'Stillwater',
              type: 'Rental',
              policyNum: policy.policyNum,
              price: policy.paymentPlan.TotalPremium,
              effectiveDate: policy.contractTerm.EffectiveDt,
              expirationDate: policy.contractTerm.ExpirationDt,
              imgsrc: '/img/renters-avatar.png',
              imgHeight: '36',
              imgWidth: '36',
              created: created
            })
        })
        condoPolicies.forEach(policy => {
          if (policy.created > 0) {
            created = moment(policy.created * 1000).format('YYYY-MM-DD')
          }
          policies.push(
            {
              provider: 'Stillwater',
              type: 'Condo',
              policyNum: policy.policyNum,
              price: policy.paymentPlan.TotalPremium,
              effectiveDate: policy.contractTerm.EffectiveDt,
              expirationDate: policy.contractTerm.ExpirationDt,
              imgsrc: '/img/condo-avatar.png',
              imgHeight: '36',
              imgWidth: '36',
              created: created
            })
        })
        // lifePolicies.forEach(policy => policies.push({ provider: policy.provider.provider, type: 'Term Life', policyNum: '123', price: policy.provider.premium, value: policy.policyvalue, term: policy.termlength, imgsrc: '/img/symetra-avatar.png', imgHeight: '36', imgWidth: '36', }))
        return policies
      },
      getLocalProvider () {
        return this.$t('provider').replace('%%provider%%', `<b>${this.$t('providerName')}</b>`).replace('%%providerName%%', `<b>${this.$t('providerName')}</b>`)
      },
      formatDate (timestamp) {
        var date = new Date(timestamp)
        var year = date.getFullYear()
        var month = ('0' + (date.getMonth() + 1)).slice(-2) // Months are zero-based
        var day = ('0' + date.getDate()).slice(-2)
        return `${year}-${month}-${day}`
      },
      getPolicy (provider, policyNum, deliveryMethod) {
        if (deliveryMethod === 'download') {
          this.load('download')
          switch (provider) {
            case 'Trupanion':
              this.$store.dispatch('getTrupanionPolicy', { policyNum: policyNum })
              break
            case 'Stillwater':
              this.$store.dispatch('getStillwaterPolicy', { policyNum: policyNum })
              break
            case 'Generali':
              this.$store.dispatch('getGeneraliPolicy', { policyNum: policyNum })
              break
            case 'Tugo':
              this.$store.dispatch('getTugoPolicy', { policyNum: policyNum })
              break
            case 'SBLI':
              this.$store.dispatch('getAfficiencyPolicy', { policyNum: policyNum })
              break
            case 'Centrian':
              this.$store.dispatch('getAfficiencyPolicy', { policyNum: policyNum })
              break
            default:
              break
          }
        }
        if (deliveryMethod === 'email') {
          this.load('email')
          switch (provider) {
            case 'Trupanion':
              this.$store.dispatch('sendTrupanionPolicy', { policyNum: policyNum }).then(() => {
                this.emailConfirmDialog = true
              })
              break
            case 'Stillwater':
              this.$store.dispatch('sendStillwaterPolicy', { policyNum: policyNum }).then(() => {
                this.emailConfirmDialog = true
              })
              break
            case 'Generali':
              this.$store.dispatch('sendGeneraliPolicy', { policyNum: policyNum }).then(() => {
                this.emailConfirmDialog = true
              })
              break
            case 'Tugo':
              this.$store.dispatch('sendTugoPolicy', { policyNum: policyNum }).then(() => {
                this.emailConfirmDialog = true
              })
              break
            case 'SBLI':
              this.$store.dispatch('sendAfficiencyPolicy', { policyNum: policyNum }).then(() => {
                this.emailConfirmDialog = true
              })
              break
            case 'Blanket Life underwritten by Humania Assurance Inc.':
              this.$store.dispatch('sendCATermPolicyPdfWithoutTemplateId', { policyNum: policyNum }).then(() => {
                this.emailConfirmDialog = true
              })
              break
            case 'Centrian':
              this.$store.dispatch('getAfficiencyPolicy', { policyNum: policyNum }).then(() => {
                this.emailConfirmDialog = true
              })
              break
            default:

              break
          }
        }
      },
      getTrupanionPolicy (policyNum) {
        this.load('download')
        this.$store.dispatch('getTrupanionPolicy', { policyNum: policyNum })
      },
      format_amt (val) {
        return localCurrency(this.$i18n.locale, val)
      },
      updateuser () {
        this.$store.dispatch('updateUserProfile', this.user)
        this.alertmessage = 'Profile has been updated'
        this.snackbar = true
      },
      async renderGeneraliPdf (item) {
        const doc = new jsPDF()
        // let generaliLogo = base64Img.base64Sync('/img/generali_logo.png')
        let contents = await imageToBase64('/img/generali_logo.png') // Image URL
        let blanket_logo = await imageToBase64('img/blanket_logo.png') // Image URL
        doc.addImage(contents, 'PNG', 15, 10, 45, 7)
        doc.addImage(blanket_logo, 'PNG', 145, 5, 52, 20)

        doc.setFontSize(12)
        doc.text('Product ID: ' + item.product, 15, 25)
        doc.text('Policy Number: ' + item.policyNum, 15, 33)
        // doc.text('Blanket.com', doc.internal.pageSize.getWidth() / 2, 20, { align: 'center' })
        // doc.text('Generali Travel Policy', doc.internal.pageSize.getWidth() / 2, 30, { align: 'center' })
        let covRecs = []
        let servRecs = []
        item.coverages.forEach(entry => {
          let indvLimit = 'Included'
          let polLimit = 'Included'

          if (entry.coveragedescription[0].includes('Trip Cancellation For Any Reason')) {
            entry.coveragedescription[0] = 'Trip Cancellation For Any Reason - 60% of penalty amount'
            indvLimit = '-'
            polLimit = '-'
          }
          if (entry.individuallimit[0] && !entry.coveragedescription[0].includes('Trip Cancellation For Any Reason')) {
            indvLimit = numeral(entry.individuallimit[0]).format('$0,00')
          }
          if (entry.policylimit[0] && !entry.coveragedescription[0].includes('Trip Cancellation For Any Reason')) {
            polLimit = numeral(entry.policylimit[0]).format('$0,00')
          }
          if (!entry.coveragedescription[0].includes('Service')) {
            covRecs.push([entry.coveragedescription, indvLimit, polLimit])
          } else {
            servRecs.push([entry.coveragedescription, indvLimit])
          }
        })
        autoTable(doc, {
          startY: 40,
          headStyles: {
            fillColor: [0, 161, 183],
          },
          head: [['Coverages Underwritten By Generali U.S. Branch', 'Individual Limit', 'Policy Limit']],
          body: covRecs,
          theme: 'striped',
        })
        autoTable(doc, {
          startY: 180,
          headStyles: {
            fillColor: [0, 161, 183],
          },
          head: [['Services Provided by Generali’s Designated Provider', '']],
          body: servRecs,
          theme: 'striped',
        })
        // let imgBase64 = jrQrcode.getQrBase64(item.tracking_number)
        // doc.addImage(imgBase64, 'JPEG', 110, 70, 75, 75)

        doc.save(item.policyNum + '_generali.pdf')
      },
      async renderTugoPdf (item) {
        const doc = new jsPDF()
        // let generaliLogo = base64Img.base64Sync('/img/generali_logo.png')
        let contents = await imageToBase64('/img/tugo_logo.png') // Image URL
        let blanket_logo = await imageToBase64('img/blanket_logo.png') // Image URL
        doc.addImage(contents, 'PNG', 15, 5, 39, 19)
        doc.addImage(blanket_logo, 'PNG', 145, 5, 52, 20)

        doc.setFontSize(12)
        doc.text('Travel Type: ' + item.tripType, 15, 30)
        doc.text('Policy Number: ' + item.policyNum, 15, 36)
        // doc.text('Blanket.com', doc.internal.pageSize.getWidth() / 2, 20, { align: 'center' })
        // doc.text('Generali Travel Policy', doc.internal.pageSize.getWidth() / 2, 30, { align: 'center' })
        let tableRecs = []
        tableRecs.push([item.tripType, item.effectiveDate, item.tripStart, item.tripEnd])

        autoTable(doc, {
          startY: 40,
          headStyles: {
            fillColor: [0, 161, 183],
          },
          head: [['Trip Type', 'Effective Date', 'Trip Start', 'Trip End']],
          body: tableRecs,
          theme: 'striped',
        })
        doc.text('Insured policy type: ' + item.policyinfo.insuredGroupType, 15, doc.lastAutoTable.finalY + 10)
        doc.text('Policy Partner: ' + item.policyinfo.partnerName + '    Partner Code: ' + item.policyinfo.partnerCode, 15, doc.lastAutoTable.finalY + 20)
        doc.text('Payment Status: ' + item.policyinfo.paymentStatus, 15, doc.lastAutoTable.finalY + 27)
        doc.text('Primary Contact: ' + item.policyinfo.primaryContact.firstName + ' ' + item.policyinfo.primaryContact.lastName, 15, doc.lastAutoTable.finalY + 34)
        doc.text('Phone: ' + item.policyinfo.primaryContact.phoneNumbers[0].areaCode + ' ' + item.policyinfo.primaryContact.phoneNumbers[0].number, 90, doc.lastAutoTable.finalY + 34)
        doc.text('Product: ' + item.policyinfo.productLineCode + ' ' + item.policyinfo.productType + ' ' + item.policyinfo.policyStatus, 15, doc.lastAutoTable.finalY + 41)

        doc.save(item.policyNum + '_tugo.pdf')
      },
      async renderTrupanionPdf (item) {
        const doc = new jsPDF()
        // let generaliLogo = base64Img.base64Sync('/img/generali_logo.png')
        let contents = await imageToBase64('/img/trupanion-logo-brown 1.png') // Image URL
        let blanket_logo = await imageToBase64('img/blanket_logo.png') // Image URL
        doc.addImage(contents, 'PNG', 15, 5, 55, 19)
        doc.addImage(blanket_logo, 'PNG', 145, 5, 52, 20)

        doc.setFontSize(12)
        doc.text('Policy Number: ' + item.PolicyNumber, 15, 35)
        doc.text('Policy Holder: ' + item.FirstName + ' ' + item.LastName, 15, 42)

        let tableRecs = []
        item.Pets.forEach(pet => {
          tableRecs.push([pet.Name, pet.BreedName, pet.PremiumCalculation.CoinsurancePercent, numeral(pet.PremiumCalculation.Premium).format('$0,000.00')])
        })

        autoTable(doc, {
          startY: 50,
          headStyles: {
            fillColor: [0, 161, 183],
          },
          head: [['Pet Name', 'Breed', 'Coverage %', 'Deductible']],
          body: tableRecs,
          theme: 'striped',
        })
        doc.save(item.PolicyNumber + '_trupanion.pdf')
      },
      generatepdf (item) {
        if (item.provider === 'Generali') {
          this.renderGeneraliPdf(item)
        }
        if (item.provider === 'Trupanion') {
          this.renderTrupanionPdf(item)
        }
        if (item.provider === 'Tugo') {
          this.renderTugoPdf(item)
        }
        // const doc = new jsPDF()
        // doc.setFontSize(22)
        // doc.text('Blanket.com', doc.internal.pageSize.getWidth() / 2, 20, { align: 'center' })
        // doc.text('Your policy', doc.internal.pageSize.getWidth() / 2, 30, { align: 'center' })

        // // let imgBase64 = jrQrcode.getQrBase64(item.tracking_number)
        // // doc.addImage(imgBase64, 'JPEG', 110, 70, 75, 75)

        // doc.save('policy.pdf')
      },
      emailPdf (policyNum) {
        // We need to dynamically send the pdf based on which policy is selected
        //
        this.load('email')
        this.$store.dispatch('sendTrupanionPolicy', { policyNum: policyNum })
        // this.$store.dispatch('sendStillwaterPolicy', { policyNum: policyNum })
        //
      },
      load (i) {
        this.btnLoading[i] = true
        setTimeout(() => (this.btnLoading[i] = false), 3000)
      },
    }
  }
</script>
<style lang="scss" scoped>
.share-btn {
  background-image: linear-gradient(to right bottom, $color-green-light, $color-green);
  position: absolute;
  right: 1rem;
  bottom: 1rem;
}

.header-txt {
font-family: roboto, sans-serif !important;
font-style: normal !important;
font-weight: 700 !important;
font-size: 12px !important;
line-height: 20px !important;
letter-spacing: 0.4px !important;
color: #757575 !important;
}

.provider-txt{
font-family: poppins, sans-serif !important;
font-weight: 400 !important;
font-size: 13px !important;
line-height: 20px !important;
color: #212121 !important;
}
.dark-grey {
  color: rgba(0, 0, 0, 0.6);
}

::v-deep .v-data-table thead tr th {
font-family: 'Roboto' !important;
font-style: normal !important;
font-weight: 700 !important;
font-size: 14px !important;
line-height: 20px !important;
letter-spacing: 0.4px !important;
color: #757575 !important;
}

::v-deep .v-data-table tbody tr td {
font-family: 'Roboto' !important;
font-size: 14px !important;
font-weight: 500 !important;
line-height: 20px !important;
letter-spacing: 0.4px !important;
color: #212121 !important;
}

.faded-green {
  color: rgba(76, 175, 80, 0.15);
}
.card {
  perspective: 150rem;
  -moz-perspective: 150rem;
  border: none !important;
  position: relative;
  height: 22rem;
  max-width: 400px;
  &__details {
    padding-top: 1rem;
    ul {
      list-style: none;
      width: 90%;
      li {
        font-size: 1.25rem;
        color: gray;
        padding: .5rem;
          border-bottom: 1px solid #eee;
        }
      }
    }
  }

  ::v-deep .v-dialog {
    overflow: hidden;
  }
</style>
